import React from 'react';
import Layout from '../layouts/fr';
import '../style/404.scss';
import { Link } from 'gatsby';

export default ({ location }) => {
  const lang = location.pathname.startsWith('/fr/') ? 'fr' : 'en';
  let message = '';
  let linkMessage = '';
  if (lang === 'fr') {
    message = "La page recherché n'existe pas ou plus.";
    linkMessage = "Revenir à l'accueil";
  } else {
    message = "We can't seem to find the page you're looking for.";
    linkMessage = 'Go to see the projects';
  }
  return (
    <Layout location={location}>
      <div className="not__found">
        <span>{message}</span>
        <Link to={lang}>{linkMessage}</Link>
      </div>
    </Layout>
  );
};
